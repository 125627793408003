.inner-content {
    flex: 1;
    overflow: auto;
    background-color: #edf2f7;
    padding: 21px;
    font-family: "Open Sans", sans-serif;
}

.ant-radio-button-wrapper {
    span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
}
